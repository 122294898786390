import { Component, ChangeDetectionStrategy, OnInit, ElementRef, ViewChild, OnDestroy, inject } from '@angular/core';
import { BehaviorSubject, debounceTime, fromEvent, map, Subscription, tap } from 'rxjs';
import { mainNavigationConfig } from './navigation.config';
import { NavigationModel } from './navigation.interface';
import { PermissionsFacade } from '@app/modules';
import { Permissions } from '@app/modules/permissions';

@Component({
    selector: 'app-side-navigation',
    templateUrl: './side-navigation.component.html',
    styleUrls: ['./side-navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavigationComponent implements OnInit, OnDestroy {
    @ViewChild('sidebar', { static: true }) el!: ElementRef;

    permissionsFacade$ = inject(PermissionsFacade);
    scrolling$ = new BehaviorSubject<boolean>(false);
    navigation$ = new BehaviorSubject<NavigationModel[]>([]);

    private subscriptions$ = new Subscription();

    ngOnInit(): void {
        this.subscriptions$.add(
            this.permissionsFacade$.permissions$
                .pipe(
                    map((permissions) =>
                        (<NavigationModel[]>JSON.parse(JSON.stringify(mainNavigationConfig))).filter((item) => this.hasAccess(permissions, item)),
                    ),
                    tap((data) => this.navigation$.next(data)),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            fromEvent(this.el.nativeElement, 'scroll')
                .pipe(
                    tap(() => {
                        if (this.scrolling$.value === false) {
                            this.scrolling$.next(true);
                        }
                    }),
                    debounceTime(200),
                    tap(() => this.scrolling$.next(false)),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    private hasAccess(permissions: Permissions, item: NavigationModel): boolean {
        if (item.children) {
            item.children = [...item.children].filter((child) => this.hasAccess(permissions, child));
            return item.children.length > 0;
        }
        return permissions.context.findIndex((context) => context.name === item.access?.context && context.actions.includes(item.access.action)) !== -1;
    }

    trackByNavigation(index: number, item: NavigationModel) {
        return item.path;
    }
}
