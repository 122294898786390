import { Component, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@env/environment';
import { PrimeNGConfig } from 'primeng/api';
import { DictionariesFacade } from './dictionaries/store/facades/dictionaries.facade';
import { LoaderService } from './gui/services/loader.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    private translateService = inject(TranslateService);
    private primengConfig = inject(PrimeNGConfig);
    private dictionariesFacade = inject(DictionariesFacade);
    private loaderService = inject(LoaderService);

    ngOnInit(): void {
        this.translateService.addLangs(environment.supportedLanguages);
        this.translateService.setDefaultLang(environment.defaultLanguage);
        this.translateService.use(environment.defaultLanguage);
        this.translateService.get('primeng').subscribe((res) => this.primengConfig.setTranslation(res));

        this.primengConfig.ripple = false;
        this.loaderService.init();
        this.dictionariesFacade.getDictionaries();
    }
}
