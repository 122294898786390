import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { generalActions } from '../actions/general.actions';

@Injectable({
    providedIn: 'root',
})
export class GeneralFacade {
    constructor(private store: Store) {}

    logout(): void {
        this.store.dispatch(generalActions.generalLogout());
    }
}
