import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpBackend, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ShellModule } from './shell';
import { GuiModule } from './gui';
import { GeneralModule } from './general';
import { HttpService } from './core/http';
import { DictionariesModule } from './dictionaries/dictionaries.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { SharedModule } from './shared/shared.module';

import { UserModule } from './modules/user';
import { PermissionsModule } from './modules/permissions';
import { CountriesStoreModule } from './store/countries';
import { DevModules } from '@env/devtool';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { VersionComponent } from './shared/components/version/version.component';
import { ResignReasonsModule } from './store/resign-reasons';

// AoT requires an exported function for faactories
export function HttpLoaderFactory(http: HttpBackend): MultiTranslateHttpLoader {
    const PREFIX = './assets/i18n';
    const SUFIX = '.json';

    return new MultiTranslateHttpLoader(http, [
        { prefix: `${PREFIX}/`, suffix: SUFIX },
        { prefix: `${PREFIX}/login/`, suffix: SUFIX },
        { prefix: `${PREFIX}/header/`, suffix: SUFIX },
        { prefix: `${PREFIX}/drivers/`, suffix: SUFIX },
        { prefix: `${PREFIX}/users/`, suffix: SUFIX },
        { prefix: `${PREFIX}/navigation/`, suffix: SUFIX },
        { prefix: `${PREFIX}/vehicles/`, suffix: SUFIX },
        { prefix: `${PREFIX}/bookings/`, suffix: SUFIX },
        { prefix: `${PREFIX}/planning/`, suffix: SUFIX },
        { prefix: `${PREFIX}/partners/`, suffix: SUFIX },
        { prefix: `${PREFIX}/services/`, suffix: SUFIX },
        { prefix: `${PREFIX}/parcels/`, suffix: SUFIX },
        { prefix: `${PREFIX}/reports/`, suffix: SUFIX },
        { prefix: `${PREFIX}/importer/`, suffix: SUFIX },
        { prefix: `${PREFIX}/settings/`, suffix: SUFIX },
        { prefix: `${PREFIX}/payments/`, suffix: SUFIX },
    ]);
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        DictionariesModule,
        ShellModule,
        UserModule,
        PermissionsModule,
        GuiModule,
        GeneralModule,
        SharedModule,
        CountriesStoreModule,
        ResignReasonsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend],
            },
        }),
        StoreModule.forRoot({}, {}),
        EffectsModule.forRoot([]),
        DevModules,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        VersionComponent,
    ],
    declarations: [AppComponent],
    providers: [
        {
            provide: HttpClient,
            useClass: HttpService,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
