import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { SimplifiedListFacade } from '../facade/simplified-list.facade';

export function simplifiedListCleanup(): CanActivateFn {
    return () => {
        const simplifiedListFacade = inject(SimplifiedListFacade);
        simplifiedListFacade.clear();
        return true;
    };
}
