import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild, inject } from '@angular/core';
import { HoverService } from '../nav-item/hover.service';
import { EnterLeave } from '../nav-item/submenu.animation';
import { NavigationModel } from '../navigation.interface';
import { PermissionsFacade } from '@app/modules';
import { Permissions } from '@app/modules/permissions';
import { map } from 'rxjs';

@Component({
    selector: 'app-subitem-nav',
    templateUrl: './subitem-nav.component.html',
    styleUrls: ['./subitem-nav.component.scss'],
    animations: [EnterLeave],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubitemNavComponent implements OnDestroy, AfterViewInit {
    @ViewChild('submenu', { static: false }) submenu!: ElementRef;

    @Input() children!: NavigationModel[] | undefined;
    @Input() top!: number;
    @Input() left!: number;

    permissionsFacade$ = inject(PermissionsFacade);

    navigation$ = this.permissionsFacade$.permissions$.pipe(map((permissions) => (this.children ?? []).filter((item) => this.hasAccess(permissions, item))));

    constructor(
        private hoverService: HoverService,
        private renderer2: Renderer2,
    ) {}

    ngAfterViewInit(): void {
        this.renderer2.appendChild(document.body, this.submenu.nativeElement);
    }

    ngOnDestroy(): void {
        this.renderer2.removeChild(document.body, this.submenu.nativeElement);
    }

    onMouseEnterSubmenu(): void {
        this.hoverService.hoverSubitem$.next(true);
    }

    onMouseLeaveSubmenu(): void {
        this.hoverService.hoverSubitem$.next(false);
    }

    onSubitemClick(): void {
        this.hoverService.hoverSubitem$.next(false);
    }

    private hasAccess(permissions: Permissions, item: NavigationModel): boolean {
        if (item.children) {
            return item.children.some((child) => this.hasAccess(permissions, child));
        }
        return permissions.context.findIndex((context) => context.name === item.access?.context && context.actions.includes(item.access.action)) !== -1;
    }
}
