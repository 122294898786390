import { Injectable, ComponentRef, Type, OnDestroy, createComponent, ApplicationRef, Renderer2, Inject, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { OverlayComponent } from '../components/overlay/overlay.component';
import { ViewBookingComponent } from '@app/services/modules/booking/components/view-booking/view-booking.component';
import { EditBookingComponent } from '@app/services/modules/booking/components/edit-booking/edit-booking.component';
import { NewBookingComponent } from '@app/services/modules/booking/components/new-booking/new-booking.component';
import { CloneBookingComponent } from '@app/services/modules/booking/components/clone-booking/clone-booking.component';
import { ViewParcelComponent } from '@app/services/modules/parcel/components/view-parcel/view-parcel.component';
import { EditParcelComponent } from '@app/services/modules/parcel/components/edit-parcel/edit-parcel.component';
import { NewParcelComponent } from '@app/services/modules/parcel/components/new-parcel/new-parcel.component';
import { CloneParcelComponent } from '@app/services/modules/parcel/components/clone-parcel/clone-parcel.component';
import { HistoryComponent } from '@app/services/modules/history/components/history/history.component';

class Params {
    'order': string | null = null;

    'booking': string | null = null;
    'booking-edit': string | null = null;
    'booking-new': 'scratch' | null = null;
    'booking-clone': string | null = null;
    'parcel': string | null = null;
    'parcel-edit': string | null = null;
    'parcel-new': 'scratch' | null = null;
    'parcel-clone': string | null = null;
    'history': string | null = null;

    constructor(data: ParamMap) {
        if (data.has('order')) {
            this.order = data.get('order');
        }

        if (data.has('booking')) {
            this.booking = data.get('booking');
        }
        if (data.has('booking-edit')) {
            this['booking-edit'] = data.get('booking-edit');
        }
        if (data.has('booking-new') && data.get('booking-new') === 'scratch') {
            this['booking-new'] = 'scratch';
        }
        if (data.has('booking-clone')) {
            this['booking-clone'] = data.get('booking-clone');
        }
        if (data.has('parcel')) {
            this.parcel = data.get('parcel');
        }
        if (data.has('parcel-edit')) {
            this['parcel-edit'] = data.get('parcel-edit');
        }
        if (data.has('parcel-new') && data.get('parcel-new') === 'scratch') {
            this['parcel-new'] = 'scratch';
        }
        if (data.has('parcel-clone')) {
            this['parcel-clone'] = data.get('parcel-clone');
        }
        if (data.has('history')) {
            this['history'] = data.get('history');
        }
    }
}

export type SLIDES = 'booking' | 'booking-edit' | 'booking-new' | 'booking-clone' | 'parcel' | 'parcel-edit' | 'parcel-new' | 'parcel-clone' | 'history';

export type SlidesComponents =
    | ViewBookingComponent
    | EditBookingComponent
    | NewBookingComponent
    | CloneBookingComponent
    | ViewParcelComponent
    | EditParcelComponent
    | NewParcelComponent
    | CloneParcelComponent
    | HistoryComponent;

@Injectable({
    providedIn: 'root',
})
export class SidebarService implements OnDestroy {
    private slides: { type: SLIDES; id: string; slideRef: ComponentRef<SlidesComponents>; overlayRef: ComponentRef<OverlayComponent> }[] = [];
    private renderer2: Renderer2;

    private readonly subscriptions$: Subscription = new Subscription();

    constructor(
        private rendererFactory: RendererFactory2,
        private applicationRef: ApplicationRef,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.renderer2 = this.rendererFactory.createRenderer(null, null);
    }

    init() {
        this.subscriptions$.add(
            this.router.events
                .pipe(
                    filter((e) => e instanceof NavigationEnd),
                    map(() => this.activatedRoute.firstChild?.snapshot.paramMap),
                    startWith(this.activatedRoute.firstChild?.snapshot.paramMap),
                    filter((params): params is ParamMap => params !== undefined),
                    map((params) => new Params(params)),
                    tap((params) => this.checkParams(params)),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    get canLeaveSlide() {
        const count = this.slides.length;

        if (count === 0) {
            return true;
        }

        const instance = this.slides[count - 1].slideRef.instance;
        if ((instance.form && instance.form.touched === false) || instance.skipConfirm) {
            return true;
        }

        instance.close();

        return false;
    }

    private checkParams(params: Params): void {
        Object.keys(params).forEach((param) => this.checkSlide(<SLIDES>param, params[<SLIDES>param]));
    }

    private checkSlide(type: SLIDES, id: string | null) {
        const slideTypeExists = this.slides.findIndex((slide) => slide.type === type) !== -1;
        const slideTypeWithIdExists = this.slides.findIndex((slide) => slide.type === type && slide.id === id) !== -1;

        if (id === null && slideTypeExists) {
            this.destroySlide(type);
        }
        if (id !== null && !slideTypeExists) {
            this.addSlide(type, id);
        }
        if (id !== null && slideTypeExists && !slideTypeWithIdExists) {
            this.replaceSlide(type, id);
        }
    }

    private addSlide(type: SLIDES, id: string) {
        switch (type) {
            case 'booking': {
                const index = this.slides.length;
                this.slides.push({
                    id: id,
                    type: type,
                    overlayRef: this.addOverlay('booking'),
                    slideRef: this.openSlide(ViewBookingComponent, index),
                });
                break;
            }
            case 'booking-edit': {
                const index = this.slides.length;
                this.slides.push({
                    id: id,
                    type: type,
                    overlayRef: this.addOverlay('booking-edit'),
                    slideRef: this.openSlide(EditBookingComponent, index),
                });
                break;
            }
            case 'booking-new': {
                const index = this.slides.length;
                this.slides.push({
                    id: id,
                    type: type,
                    overlayRef: this.addOverlay('booking-new'),
                    slideRef: this.openSlide(NewBookingComponent, index),
                });
                break;
            }
            case 'booking-clone': {
                const index = this.slides.length;
                this.slides.push({
                    id: id,
                    type: type,
                    overlayRef: this.addOverlay('booking-clone'),
                    slideRef: this.openSlide(CloneBookingComponent, index),
                });
                break;
            }
            case 'parcel': {
                const index = this.slides.length;
                this.slides.push({
                    id: id,
                    type: type,
                    overlayRef: this.addOverlay('parcel'),
                    slideRef: this.openSlide(ViewParcelComponent, index),
                });
                break;
            }
            case 'parcel-edit': {
                const index = this.slides.length;
                this.slides.push({
                    id: id,
                    type: type,
                    overlayRef: this.addOverlay('parcel-edit'),
                    slideRef: this.openSlide(EditParcelComponent, index),
                });
                break;
            }
            case 'parcel-new': {
                const index = this.slides.length;
                this.slides.push({
                    id: id,
                    type: type,
                    overlayRef: this.addOverlay('parcel-new'),
                    slideRef: this.openSlide(NewParcelComponent, index),
                });
                break;
            }
            case 'parcel-clone': {
                const index = this.slides.length;
                this.slides.push({
                    id: id,
                    type: type,
                    overlayRef: this.addOverlay('parcel-clone'),
                    slideRef: this.openSlide(CloneParcelComponent, index),
                });
                break;
            }
            case 'history': {
                const index = this.slides.length;
                this.slides.push({
                    id: id,
                    type: type,
                    overlayRef: this.addOverlay('history'),
                    slideRef: this.openSlide(HistoryComponent, index),
                });
                break;
            }
        }
    }

    private replaceSlide(type: SLIDES, id: string) {
        const index = this.slides.findIndex((slide) => slide.type === type);

        if (index === -1) {
            return;
        }

        switch (type) {
            case 'booking': {
                this.closeSlide(this.slides[index].slideRef);
                const slide = {
                    ...this.slides[index],
                    id: id,
                    slideRef: this.openSlide(ViewBookingComponent, index),
                };
                this.slides.splice(index, 1, slide);
                break;
            }
            case 'booking-edit': {
                this.closeSlide(this.slides[index].slideRef);
                const slide = {
                    ...this.slides[index],
                    id: id,
                    slideRef: this.openSlide(EditBookingComponent, index),
                };
                this.slides.splice(index, 1, slide);
                break;
            }
            case 'booking-new': {
                this.closeSlide(this.slides[index].slideRef);
                const slide = {
                    ...this.slides[index],
                    id: id,
                    slideRef: this.openSlide(NewBookingComponent, index),
                };
                this.slides.splice(index, 1, slide);
                break;
            }
            case 'booking-clone':
                {
                    this.closeSlide(this.slides[index].slideRef);
                    const slide = {
                        ...this.slides[index],
                        id: id,
                        slideRef: this.openSlide(CloneBookingComponent, index),
                    };
                    this.slides.splice(index, 1, slide);
                }
                break;
            case 'parcel':
                {
                    this.closeSlide(this.slides[index].slideRef);
                    const slide = {
                        ...this.slides[index],
                        id: id,
                        slideRef: this.openSlide(ViewParcelComponent, index),
                    };
                    this.slides.splice(index, 1, slide);
                }
                break;
            case 'parcel-edit':
                {
                    this.closeSlide(this.slides[index].slideRef);
                    const slide = {
                        ...this.slides[index],
                        id: id,
                        slideRef: this.openSlide(EditParcelComponent, index),
                    };
                    this.slides.splice(index, 1, slide);
                }
                break;
            case 'parcel-new':
                {
                    this.closeSlide(this.slides[index].slideRef);
                    const slide = {
                        ...this.slides[index],
                        id: id,
                        slideRef: this.openSlide(NewParcelComponent, index),
                    };
                    this.slides.splice(index, 1, slide);
                }
                break;
            case 'parcel-clone':
                {
                    this.closeSlide(this.slides[index].slideRef);
                    const slide = {
                        ...this.slides[index],
                        id: id,
                        slideRef: this.openSlide(CloneParcelComponent, index),
                    };
                    this.slides.splice(index, 1, slide);
                }
                break;
            case 'history':
                {
                    this.closeSlide(this.slides[index].slideRef);
                    const slide = {
                        ...this.slides[index],
                        id: id,
                        slideRef: this.openSlide(HistoryComponent, index),
                    };
                    this.slides.splice(index, 1, slide);
                }
                break;
        }
    }

    private destroySlide(type: SLIDES) {
        const index = this.slides.findIndex((slide) => slide.type === type);
        this.closeSlide(this.slides[index].slideRef);
        this.removeOverlay(this.slides[index].overlayRef);
        this.slides.splice(index, 1);
    }

    private openSlide(compnent: Type<SlidesComponents>, index: number) {
        const slideComponentRef = createComponent(compnent, {
            environmentInjector: this.applicationRef.injector,
        });

        slideComponentRef.instance.setSlideIndex(index);

        this.applicationRef.attachView(slideComponentRef.hostView);
        this.renderer2.appendChild(this.document.querySelector('body'), slideComponentRef.location.nativeElement);
        return slideComponentRef;
    }

    private closeSlide(ref: ComponentRef<SlidesComponents>): void {
        this.renderer2.removeChild(this.document.querySelector('body'), ref);
        ref.destroy();
    }

    private addOverlay(type: SLIDES) {
        const overlayComponentRef = createComponent(OverlayComponent, {
            environmentInjector: this.applicationRef.injector,
        });

        overlayComponentRef.instance.setType(type);

        this.applicationRef.attachView(overlayComponentRef.hostView);
        this.renderer2.appendChild(this.document.querySelector('body'), overlayComponentRef.location.nativeElement);
        return overlayComponentRef;
    }

    private removeOverlay(ref: ComponentRef<OverlayComponent>) {
        this.renderer2.removeChild(this.document.querySelector('body'), ref);
        ref.destroy();
    }
}
