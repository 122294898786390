<ul
    #submenu
    class="sub__nav_wrapper unstyled"
    @EnterLeave
    (mouseenter)="onMouseEnterSubmenu()"
    (mouseleave)="onMouseLeaveSubmenu()"
    [style.top.px]="top"
    [style.left.px]="left"
>
    <li *ngFor="let subitem of children">
        <a [routerLink]="subitem.path" class="text-white fs-12 unstyled d-block p-4 text-start" (click)="onSubitemClick()">{{ subitem.title | translate }}</a>
    </li>
</ul>
