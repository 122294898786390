import { Component, OnInit } from '@angular/core';
import { SidebarService } from '@app/shell/sidebar/services/sidebar.service';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
    providers: [],
})
export class ShellComponent implements OnInit {
    constructor(private sidebarService: SidebarService) {}

    ngOnInit(): void {
        this.sidebarService.init();
    }
}
