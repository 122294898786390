import { Context, Actions } from '@app/modules/permissions';
import { NavigationModel } from './navigation.interface';

export const mainNavigationConfig: NavigationModel[] = [
    {
        title: 'home',
        path: 'dashboard',
        class: 'nav-link',
        activeClass: 'active',
        icon: 'pi pi-home',
        access: {
            context: Context.DASHBOARD,
            action: Actions.VIEW,
        },
    },
    {
        title: 'routes',
        path: 'planning',
        class: 'nav-link',
        activeClass: 'active',
        icon: 'pi pi-map',
        access: {
            context: Context.PLANNING,
            action: Actions.VIEW,
        },
    },
    {
        title: 'services',
        path: 'services/bookings',
        class: 'nav-link',
        activeClass: 'active',
        icon: 'pi pi-shopping-cart',
        children: [
            {
                title: 'bookings',
                path: 'services/bookings',
                access: {
                    context: Context.BOOKING,
                    action: Actions.VIEW,
                },
            },
            {
                title: 'parcels',
                path: 'services/parcels',
                access: {
                    context: Context.PARCEL,
                    action: Actions.VIEW,
                },
            },
        ],
    },
    {
        title: 'drivers',
        path: 'drivers',
        class: 'nav-link',
        activeClass: 'active',
        icon: 'pi pi-id-card',
        access: {
            context: Context.DRIVERS,
            action: Actions.VIEW,
        },
    },
    {
        title: 'cars',
        path: 'vehicles',
        class: 'nav-link',
        activeClass: 'active',
        icon: 'pi pi-car',
        access: {
            context: Context.VEHICLES,
            action: Actions.VIEW,
        },
    },
    {
        title: 'users',
        path: 'users',
        class: 'nav-link',
        activeClass: 'active',
        icon: 'pi pi-user',
        access: {
            context: Context.USERS,
            action: Actions.VIEW,
        },
    },
    {
        title: 'partners',
        path: 'partners',
        class: 'nav-link',
        activeClass: 'active',
        icon: 'pi pi-users',
        access: {
            context: Context.PARTNERS,
            action: Actions.VIEW,
        },
    },
    {
        title: 'reports',
        path: 'reports',
        class: 'nav-link',
        activeClass: 'active',
        icon: 'pi pi-chart-line',
        children: [
            {
                title: 'invoice_report',
                path: 'reports/invoice-data-report',
                access: {
                    context: Context.REPORT_INVOICE,
                    action: Actions.VIEW,
                },
            },
            {
                title: 'driver_reports',
                path: 'reports/drivers-report',
                access: {
                    context: Context.REPORT_DRIVERS_COURSES,
                    action: Actions.VIEW,
                },
            },
            {
                title: 'partner_report',
                path: 'reports/partner-report',
                access: {
                    context: Context.REPORT_PARTNER,
                    action: Actions.VIEW,
                },
            },
            {
                title: 'schedule_report',
                path: 'reports/schedule-report',
                access: {
                    context: Context.REPORT_SCHEDULE,
                    action: Actions.VIEW,
                },
            },
            {
                title: 'cash_collected_report',
                path: 'reports/cash-collected-report',
                access: {
                    context: Context.REPORT_DRIVERS_CASH,
                    action: Actions.VIEW,
                },
            },
            {
                title: 'simplified_person_list',
                path: 'reports/simplified-person-list',
                access: {
                    context: Context.PERSON_LIST,
                    action: Actions.VIEW,
                },
            },
            {
                title: 'partner_departure',
                path: 'reports/partner-departure',
                access: {
                    context: Context.PARTNER_DEPARTURE,
                    action: Actions.VIEW,
                },
            },
        ],
    },
    {
        title: 'file_importer',
        path: 'importer',
        class: 'nav-link',
        activeClass: 'active',
        icon: 'pi pi-cloud-download',
        access: {
            context: Context.IMPORT,
            action: Actions.VIEW,
        },
    },
];
