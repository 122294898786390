import { Inject, Injectable, InjectionToken, Injector, Optional } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiPrefixInterceptor, ApiTokenInterceptor, ApiErrorInterceptor, ApiTokenRefreshInterceptor, ApiHeremapsRouteInterceptor } from '../interceptors';

// HttpClient is declared in a re-exported module, so we have to extend the original module to make it work properly
// (see https://github.com/Microsoft/TypeScript/issues/13897)
declare module '@angular/common/http' {
    // Augment HttpClient with the added configuration methods from HttpService, to allow in-place replacement of
    // HttpClient with HttpService using dependency injection
    export interface HttpClient {
        /**
         * Skips default error handler for this request.
         * @return {HttpClient} The new instance.
         */
        skipErrorHandler(): HttpClient;

        /**
         * Do not use API prefix for this request.
         * @return {HttpClient} The new instance.
         */
        skipApiPrefix(): HttpClient;

        /**
         * Skips Token
         * @return {HttpClient} The new instance.
         */
        skipToken(): HttpClient;

        /**
         * Skips Refresh Token
         * @return {HttpClient} The new instance.
         */
        skipRefreshToken(): HttpClient;

        /**
         * Route Prefix
         * @return {HttpClient} The new instance.
         */
        addHerempasRoutePrefix(): HttpClient;

        /**
         * Autosuggest Prefix
         * @return {HttpClient} The new instance.
         */
        addHerempasAutosuggestPrefix(): HttpClient;
    }
}

// From @angular/common/http/src/interceptor: allows to chain interceptors
class HttpInterceptorHandler implements HttpHandler {
    constructor(
        private next: HttpHandler,
        private interceptor: HttpInterceptor,
    ) {}

    handle(request: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
        return this.interceptor.intercept(request, this.next);
    }
}

/**
 * Allows to override default dynamic interceptors that can be disabled with the HttpService extension.
 * Except for very specific needs, you should better configure these interceptors directly in the constructor below
 * for better readability.
 *
 * For static interceptors that should always be enabled (like ApiPrefixInterceptor), use the standard
 * HTTP_INTERCEPTORS token.
 */
export const HTTP_DYNAMIC_INTERCEPTORS = new InjectionToken<HttpInterceptor>('HTTP_DYNAMIC_INTERCEPTORS');

/**
 * Extends HttpClient with per request configuration using dynamic interceptors.
 */
@Injectable({
    providedIn: 'root',
})
export class HttpService extends HttpClient {
    constructor(
        private httpHandler: HttpHandler,
        private injector: Injector,
        @Optional() @Inject(HTTP_DYNAMIC_INTERCEPTORS) private interceptors: HttpInterceptor[] = [],
    ) {
        super(httpHandler);

        if (!this.interceptors) {
            // Configure default interceptors that can be disabled here
            this.interceptors = [
                this.injector.get(ApiTokenInterceptor),
                this.injector.get(ApiPrefixInterceptor),
                this.injector.get(ApiErrorInterceptor),
                this.injector.get(ApiTokenRefreshInterceptor),
            ];
        }
    }

    override skipErrorHandler(): HttpClient {
        return this.removeInterceptor(ApiErrorInterceptor);
    }

    override skipToken(): HttpClient {
        return this.removeInterceptor(ApiTokenInterceptor);
    }

    override skipApiPrefix(): HttpClient {
        return this.removeInterceptor(ApiPrefixInterceptor);
    }

    override skipRefreshToken(): HttpClient {
        return this.removeInterceptor(ApiTokenRefreshInterceptor);
    }

    override addHerempasRoutePrefix(): HttpClient {
        return this.removeInterceptor(ApiErrorInterceptor)
            .removeInterceptor(ApiTokenInterceptor)
            .removeInterceptor(ApiPrefixInterceptor)
            .removeInterceptor(ApiTokenRefreshInterceptor)
            .addInterceptor(ApiHeremapsRouteInterceptor);
    }

    // Override the original method to wire interceptors when triggering the request.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    override request(method?: any, url?: any, options?: any): any {
        const handler = this.interceptors.reduceRight((next, interceptor) => new HttpInterceptorHandler(next, interceptor), this.httpHandler);
        return new HttpClient(handler).request(method, url, options);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    private removeInterceptor(interceptorType: Function): HttpService {
        return new HttpService(
            this.httpHandler,
            this.injector,
            this.interceptors.filter((i) => !(i instanceof interceptorType)),
        );
    }

    private addInterceptor(interceptorType: unknown): HttpService {
        return new HttpService(this.httpHandler, this.injector, [...this.interceptors, this.injector.get(interceptorType) as HttpInterceptor]);
    }
}
