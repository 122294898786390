import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { PartnerPricingFacade } from '../facades/partner-pricing.facade';

export function partnerPricingCleanupGuard(): CanActivateFn {
    return () => {
        const partnerPricingFacade = inject(PartnerPricingFacade);
        partnerPricingFacade.clear();
        return true;
    };
}
