import { Component } from '@angular/core';
import { GeneralFacade } from '@app/general/store';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    constructor(private generalFacade: GeneralFacade) {}

    onLogoutClick(): void {
        this.generalFacade.logout();
    }
}
