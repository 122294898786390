import { createAction } from '@ngrx/store';

export enum GeneralActionTypes {
    GENERAL_LOGOUT = '[General] Logout',
}

const generalLogout = createAction(GeneralActionTypes.GENERAL_LOGOUT);

export const generalActions = {
    generalLogout,
};
