import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '@env/environment';
import { build } from 'build';

@Component({
    standalone: true,
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule],
})
export class VersionComponent {
    readonly environment = environment;
    readonly build = build;

    get time() {
        return environment.showVersion === 'time' || environment.showVersion === 'all';
    }

    get tag() {
        return environment.showVersion === 'tag' || environment.showVersion === 'all';
    }
}
