import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, map } from 'rxjs';

@Injectable()
export class HoverService {
    hoverItem$ = new BehaviorSubject(false);
    hoverSubitem$ = new BehaviorSubject(false);
    hoverNav$ = combineLatest([this.hoverItem$, this.hoverSubitem$]).pipe(
        debounceTime(5),
        map((hovers) => hovers.includes(true)),
    );
}
