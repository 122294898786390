import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from './shell';
import { CanLeaveGuard } from './shell/sidebar/guards/can-leave.guard';
import { countriesCleanupGuard, countriesLoadedGuard } from './store/countries';
import { IsUserLoggedGuard } from './core/guards';
import { partnerPricingCleanupGuard } from './store/partner-pricing/guards/cleanup.guard';
import { CanEnterContextGuard } from './modules/permissions/guards/can-enter.guard';
import { Actions, Context } from './modules/permissions';
import { PlanningQueryCleanup } from './store/planning';
import { resingReasonsCleanupGuard } from './store/resign-reasons/guards/cleanup.guard';
import { simplifiedListCleanup } from './store/simplified-list/guards/cleanup.guard';
import { selectOptionsCleanupGuard } from './store/select-options/guards/cleanup.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 's/dashobard',
        pathMatch: 'full',
    },
    {
        path: 'login',
        canActivate: [countriesCleanupGuard(), partnerPricingCleanupGuard(), simplifiedListCleanup(), resingReasonsCleanupGuard, selectOptionsCleanupGuard()],
        loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    },
    {
        path: 's',
        canDeactivate: [CanLeaveGuard],
        canActivate: [IsUserLoggedGuard],
        children: [
            {
                path: '',
                canActivate: [countriesLoadedGuard],
                children: [
                    Shell.childRoutes([
                        {
                            path: '',
                            redirectTo: 'dashboard',
                            pathMatch: 'full',
                        },
                        {
                            path: 'dashboard',
                            loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
                            data: {
                                context: Context.DASHBOARD,
                                action: Actions.VIEW,
                            },
                            canActivate: [CanEnterContextGuard],
                        },
                        {
                            path: 'drivers',
                            loadChildren: () => import('./drivers/drivers.module').then((m) => m.DriversModule),
                            data: {
                                context: Context.DRIVERS,
                                action: Actions.VIEW,
                            },
                            canActivate: [CanEnterContextGuard],
                        },
                        {
                            path: 'users',
                            loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
                            data: {
                                context: Context.USERS,
                                action: Actions.VIEW,
                            },
                            canActivate: [CanEnterContextGuard],
                        },
                        {
                            path: 'vehicles',
                            loadChildren: () => import('./vehicles/vehicles.module').then((m) => m.VehiclesModule),
                            data: {
                                context: Context.VEHICLES,
                                action: Actions.VIEW,
                            },
                            canActivate: [CanEnterContextGuard],
                        },
                        {
                            path: 'services',
                            loadChildren: () => import('./services/services.module').then((m) => m.ServicesModule),
                        },
                        {
                            path: 'planning',
                            loadChildren: () => import('./planning/planning.module').then((m) => m.PlanningModule),
                            data: {
                                context: Context.PLANNING,
                                action: Actions.VIEW,
                            },
                            canActivate: [CanEnterContextGuard],
                            canDeactivate: [PlanningQueryCleanup],
                        },
                        {
                            path: 'partners',
                            loadChildren: () => import('./partners/partners.module').then((m) => m.PartnersModule),
                            data: {
                                context: Context.PARTNERS,
                                action: Actions.VIEW,
                            },
                            canActivate: [CanEnterContextGuard],
                        },
                        {
                            path: 'reports',
                            loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
                        },
                        {
                            path: 'importer',
                            loadChildren: () => import('./importer/importer.module').then((m) => m.ImporterModule),
                            data: {
                                context: Context.IMPORT,
                                action: Actions.VIEW,
                            },
                            canActivate: [CanEnterContextGuard],
                        },
                        { path: 'settings', loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule) },
                        {
                            path: 'development',
                            loadChildren: () => import('./development/development.module').then((m) => m.DevelopmentModule),
                        },
                        {
                            path: 'test',
                            loadChildren: () => import('./test/test.module').then((m) => m.TestModule),
                            canActivate: [],
                        },
                    ]),
                ],
            },
        ],
    },
    {
        path: '**',
        redirectTo: 's/dashboard',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
