import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { SelectOptionsFacade } from '../facades/select-options.facade';

export function selectOptionsCleanupGuard(): CanActivateFn {
    return () => {
        const selectOptionsFacade = inject(SelectOptionsFacade);
        selectOptionsFacade.clear();
        return true;
    };
}
