import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { GuiFacade } from '@app/gui/store/facades/gui-facade';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CanLeaveUrlGuard {
    constructor(private guiFacade: GuiFacade) {}

    canDeactivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let canLeave = true;
        this.guiFacade.selectConfirmDialog().subscribe((val) => {
            canLeave = val;
        });
        return !canLeave;
    }
}
