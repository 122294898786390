<div>
    <li (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" class="nav__item">
        <a [routerLink]="item.path" [routerLinkActive]="item.activeClass ? item.activeClass : ''" class="{{ item.class }} unstyled">
            <i *ngIf="item?.icon?.length" [ngClass]="item.icon"></i>
            <span>{{ item.title | translate }}</span>
        </a>
    </li>

    <app-subitem-nav [children]="item.children" *ngIf="(hover$ | async) && !scrolling && item.children && item.children.length > 0" [top]="top" [left]="left" />
</div>
