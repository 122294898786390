import { Injectable } from '@angular/core';
import { mergeMap, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { generalActions } from '../actions/general.actions';
import { AuthService } from '@app/core/auth';
import { UserFacade } from '@app/modules/user/store';
import { Router } from '@angular/router';
import { ProfileFacade } from '@app/store/user-profile';

@Injectable()
export class GeneralEffects {
    logoutUser$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(generalActions.generalLogout),
                mergeMap(() => {
                    return this.authService.logout().pipe(
                        tap(() => {
                            this.userFacade.clearState();
                            this.profileFacade.clear();
                            this.router.navigateByUrl('/login');
                        }),
                    );
                }),
            );
        },
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private userFacade: UserFacade,
        private router: Router,
        private profileFacade: ProfileFacade,
    ) {}
}
