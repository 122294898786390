import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { filter, map, tap } from 'rxjs';
import { UserFacade } from '@app/modules/user/store';

export const IsUserLoggedGuard: CanActivateFn = () => {
    const userFacade = inject(UserFacade);
    const router = inject(Router);

    return userFacade.state$.pipe(
        tap((state) => {
            if (state.executed === false) {
                userFacade.getUserDataAction();
            }
        }),
        filter((state) => state.executed),
        map((state) => {
            if (state.user === null) {
                return router.createUrlTree(['/login']);
            }
            return true;
        }),
    );
};
