import { Injectable, Injector } from '@angular/core';
import { Location } from '@angular/common';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, switchMap } from 'rxjs';
import { AuthService } from '../../auth/service/auth.service';
import { Router } from '@angular/router';
import { UserFacade } from '@app/modules/user/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GuiActions } from '@app/gui/store/actions/gui.actions';

/**
 *  Call refrest token request on 401 error
 */
@Injectable({
    providedIn: 'root',
})
export class ApiTokenRefreshInterceptor implements HttpInterceptor {
    private skipRedirectOnPath = ['/login/reset-password', 'login/passoword-reset'];

    private inflightAuthRequest!: null | Observable<Request>;
    private authService!: AuthService;

    constructor(
        private injector: Injector,
        private store: Store,
        private userFacade: UserFacade,
        private router: Router,
        private location: Location,
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.authService = this.injector.get(AuthService);
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    return this.refreshTokenRequest(next, request);
                }
                throw error;
            }),
        );
    }

    /**
     * Token Refresh process
     */
    private refreshTokenRequest(next: HttpHandler, authReq: HttpRequest<unknown>) {
        const translateService = this.injector.get(TranslateService);
        let authReqRepeat!: HttpRequest<unknown>;
        return this.inflightAuth.pipe(
            switchMap(() => {
                this.clearInflightAuth();
                authReqRepeat = authReq.clone();
                return next.handle(authReqRepeat);
            }),
            catchError((error: HttpErrorResponse) => {
                // show message
                if (error.status === 401 && this.inflightAuthRequest && this.userFacade.state.executed === true) {
                    this.store.dispatch(
                        GuiActions.showErrorToastAction({
                            options: {
                                summary: translateService.instant('GUI.TOAST.session_expired') as string,
                            },
                        }),
                    );
                }

                // remove inflight auth request
                this.clearInflightAuth();

                // return
                if (error.status === 401) {
                    this.userFacade.clearState();
                    const path = this.location.path().split('?')[0];
                    if (!this.skipRedirectOnPath.includes(path)) {
                        this.router.navigate(['/login', 'sign-in']);
                    }
                }

                throw error;
            }),
        );
    }

    /**
     * Get Inflight Auth
     */
    private get inflightAuth(): Observable<Request> {
        if (!this.inflightAuthRequest) {
            this.inflightAuthRequest = this.authService.refresh();
        }
        return this.inflightAuthRequest;
    }

    /**
     * Clear inflightAuthRequest
     */
    private clearInflightAuth() {
        this.inflightAuthRequest = null;
    }
}
