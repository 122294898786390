import { Routes, Route } from '@angular/router';

import { ShellComponent } from '../components/shell/shell.component';

/**
 * Provides helper methods to create routes.
 */
export class Shell {
    /**
     * Creates routes using the shell component and authentication.
     * @param routes The routes to add.
     * @return The new route using shell as the base.
     */
    static childRoutes(shellRoutes: Routes): Route {
        return {
            path: '',
            component: ShellComponent,
            children: shellRoutes,
        };
    }
}
