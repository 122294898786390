import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import { ShowHide } from '../../animations/sidebar.animation';
import { Subscription, fromEvent } from 'rxjs';
import { SLIDES } from '../../services/sidebar.service';

@Component({
    selector: 'app-overlary',
    styleUrls: ['./overlay.component.scss'],
    templateUrl: './overlay.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [ShowHide],
})
export class OverlayComponent implements OnDestroy {
    type?: SLIDES;

    click$ = fromEvent(this.elementRef.nativeElement, 'click');
    private subscriptions$ = new Subscription();

    constructor(private elementRef: ElementRef) {}

    @HostBinding('@showHide') get(): void {}

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    setType(type: SLIDES) {
        this.type = type;
    }
}
