import { trigger, transition, animate, style } from '@angular/animations';

export const EnterLeave = trigger('EnterLeave', [
    transition(':enter', [
        style({
            opacity: 0,
            transform: 'translateX(-2rem)',
        }),
        animate(
            '0.25s cubic-bezier(0.5, 0, 0.1, 1)',
            style({
                opacity: 1,
                transform: 'translateX(0)',
            }),
        ),
    ]),
    transition(':leave', [
        animate(
            '0.25s cubic-bezier(0.5, 0, 0.1, 1)',
            style({
                opacity: 0,
                transform: 'translateX(0)',
            }),
        ),
    ]),
]);
