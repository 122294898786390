import { Component, ChangeDetectionStrategy, Input, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationModel } from '../navigation.interface';
import { HoverService } from './hover.service';
import { EnterLeave } from './submenu.animation';

@Component({
    selector: 'app-nav-item',
    templateUrl: './nav-item.component.html',
    styleUrls: ['./nav-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [EnterLeave],
    providers: [HoverService],
})
export class NavItemComponent {
    @Input() item!: NavigationModel;
    @Input() scrolling!: boolean | null;
    hover$ = this.hoverService.hoverNav$;

    constructor(
        private hoverService: HoverService,
        private elementRef: ElementRef,
    ) {}

    showSubMenu$ = new BehaviorSubject<boolean>(false);

    onMouseEnter(): void {
        this.hoverService.hoverItem$.next(true);
    }

    onMouseLeave(): void {
        this.hoverService.hoverItem$.next(false);
    }

    onSubitemClick(): void {
        this.hoverService.hoverItem$.next(false);
    }

    get position(): DOMRect {
        return (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect();
    }

    get left(): number {
        return this.position.x + this.position.width;
    }

    get top(): number {
        return this.position.y - 18 + this.position.height / 2;
    }
}
