import { ComponentRef, Injectable } from '@angular/core';

import { SidebarService } from '../services/sidebar.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class CanLeaveGuard {
    constructor(
        private sidebarService: SidebarService,
        private router: Router,
    ) {}

    canDeactivate(component: ComponentRef<unknown>, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) {
        const nextUrlTree = this.router.parseUrl(nextState.url);
        const nextParams = Object.keys(nextUrlTree.root.children['primary'].segments[0].parameters);
        if (nextParams.includes('booking-edit') || nextParams.includes('parcel-edit') || nextParams.includes('history')) {
            return true;
        }
        return this.sidebarService.canLeaveSlide;
    }
}
