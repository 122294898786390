import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { ResponseError } from '@app/shared/models/api-response.dtos';
import { Store } from '@ngrx/store';
import { GuiActions } from '@app/gui/store/actions/gui.actions';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ApiErrorInterceptor implements HttpInterceptor {
    constructor(
        private store: Store,
        private injector: Injector,
        private router: Router,
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof Blob && error.status === 422 && error.error.type === 'application/json') {
                    return this.blobErrorHandler(error);
                } else {
                    return this.errorHandler(error);
                }
            }),
        );
    }

    private errorHandler(response: HttpErrorResponse): Observable<HttpEvent<unknown>> {
        this.errorCodes(response);
        throw response;
    }

    private errorCodes(response: HttpErrorResponse) {
        const translateService = this.injector.get(TranslateService);
        const errors = (<{ errors: ResponseError[] }>response.error).errors;
        switch (response.status) {
            case 401:
                // Refresh Token Interceptor handles this
                break;
            case 403:
                this.store.dispatch(
                    GuiActions.showErrorToastAction({
                        options: { summary: translateService.instant('FORMS.ERRORS.Http403') as string },
                    }),
                );
                break;
            case 404:
                this.store.dispatch(
                    GuiActions.showErrorToastAction({
                        options: { summary: translateService.instant('FORMS.ERRORS.Http404') as string },
                    }),
                );
                this.router.navigate(['../..'], { relativeTo: this.returnRoute() });
                break;
            case 422:
                // For form Validation, handled inside components
                break;
            case 400:
                // Handled inside component
                break;
            default:
                if (errors && errors[0]?.title) {
                    this.store.dispatch(GuiActions.showErrorToastAction({ options: { summary: errors[0].title } }));
                } else {
                    this.store.dispatch(
                        GuiActions.showErrorToastAction({
                            options: { summary: translateService.instant('FORMS.ERRORS.HttpGeneral') as string },
                        }),
                    );
                }
                break;
        }
    }

    private blobErrorHandler(error: HttpErrorResponse): Promise<HttpEvent<unknown>> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                try {
                    const errmsg = JSON.parse(reader.result as string) as string;
                    reject(
                        new HttpErrorResponse({
                            error: errmsg,
                        }),
                    );
                } catch (e) {
                    reject(error);
                }
            };
            reader.onerror = () => {
                reject(error);
            };
            // #FIXME
            reader.readAsText(<Blob>error.error);
        });
    }

    returnRoute = (): ActivatedRoute => {
        let route = this.router.routerState.root;
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    };
}
