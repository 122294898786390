import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { USER_FEATURE_KEY, userReducer, UserEffects } from './store';
@NgModule({
    imports: [CommonModule, StoreModule.forFeature(USER_FEATURE_KEY, userReducer), EffectsModule.forFeature([UserEffects])],
})
export class UserModule {}
