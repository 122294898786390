<header class="tw-fixed tw-z-[400] tw-w-full tw-shadow-xl tw-px-4 tw-bg-white">
    <div class="tw-flex tw-items-center tw-h-[3.5rem]">
        <a routerLink="dashboard" class="tw-leading-none tw-text-[0]">
            <img src="/assets/images/logo-gtv.svg" alt="GTV Logo" />
        </a>
        <ul class="tw-flex tw-ms-auto tw-items-center tw-gap-x-2">
            <li class="tw-me-6">
                <app-user-info />
            </li>
            <li>
                <p-button routerLink="settings" [text]="true" [rounded]="true" icon="pi pi-cog" />
            </li>
            <li>
                <p-button (click)="onLogoutClick()" [text]="true" [rounded]="true" icon="pi pi-power-off" />
            </li>
        </ul>
    </div>
</header>
