import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { AvatarModule } from 'primeng/avatar';
import { ShellComponent } from './components/shell/shell.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SideNavigationComponent } from './components/side-navigation/side-navigation.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { NavItemComponent } from './components/side-navigation/nav-item/nav-item.component';
import { SubitemNavComponent } from './components/side-navigation/subitem-nav/subitem-nav.component';
import { ProfileStoreModule } from '@app/store/user-profile';

@NgModule({
    declarations: [ShellComponent, HeaderComponent, FooterComponent, SideNavigationComponent, UserInfoComponent, NavItemComponent, SubitemNavComponent],
    imports: [CommonModule, SharedModule, AvatarModule, RouterModule, ProfileStoreModule],
})
export class ShellModule {}
