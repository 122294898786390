import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { UserFacade } from '@app/modules';
import { map } from 'rxjs';

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoComponent {
    private readonly userFacade = inject(UserFacade);

    readonly name$ = this.userFacade.user$.pipe(map((user) => `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim() ?? '-'));
    readonly initials$ = this.userFacade.user$.pipe(
        map((user) => `${user?.firstName ? user.firstName.slice(0, 1) : ''}${user?.lastName ? user.lastName.slice(0, 1) : ''}`.trim() ?? '--'),
    );
}
